


































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class Life extends Vue {
  isActive = false;
  mounted() {
    // setTimeout(() => {
    //   this.isActive = true;
    // }, 1000);
    this.$nextTick(() => {
      this.isActive = true;
    });
  }
}
